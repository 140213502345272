import React, { useState } from "react";
import { Helmet } from "react-helmet";
import MD5 from "crypto-js/md5";

const BBBVoter = () => {
    const [password, setPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const correctPassword = "9c12d55519b2765c6a4c279c5244b819";

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (MD5(password).toString() === correctPassword) {
            setIsAuthenticated(true);
        } else {
            alert("Incorrect password!");
        }
    };

    if (!isAuthenticated) {
        return (
            <>
                <Helmet>
                    <title>Victor Springer | Restricted Area</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <div className="flex flex-col items-center justify-center bg-dracula-background text-dracula-foreground">
                    <header className="text-center">
                        <h1 className="text-dracula-pink text-4xl font-semibold mb-4">Restricted Area<span className="cursor"></span></h1>
                    </header>
                    <h2 className="text-2xl text-dracula-green mb-4">Enter Password</h2>
                    <form onSubmit={handlePasswordSubmit} className="space-y-4">
                        <input
                            required
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2 bg-dracula-grey text-dracula-foreground border border-dracula-comment rounded"
                        />
                        <button
                            type="submit"
                            className="bg-dracula-green text-dracula-background px-4 py-2 rounded shadow font-semibold"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>Victor Springer | BBBVoter</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <header className="max-w-2xl mx-auto text-center">
                <h1 className="text-dracula-pink text-4xl font-semibold mb-4">BBBVoter<span className="cursor"></span></h1>
            </header>

            <main className="max-w-4xl mx-auto text-center py-16">
                <section className="mb-12">
                    <h2 className="text-dracula-yellow text-4xl text-center mb-2">
                        Windows
                    </h2>
                    <a href="https://drive.google.com/file/d/1L6mXFhjyeh-yJlZeL8rur40YVg1rxGnw/view?usp=drive_link" className="text-dracula-cyan text-lg inline-block hover:text-dracula-green font-bold">
                        Download
                    </a>
                </section>

                <section className="mb-12">
                    <h2 className="text-dracula-yellow text-4xl text-center mb-2">
                        MacOS
                    </h2>
                    <div className="mb-12 flex justify-center space-x-6">
                        <a href="https://drive.google.com/file/d/1rllafJAXkZgxus9g0aG47ZLAApnN3Nsc/view?usp=drive_link" className="text-dracula-cyan text-lg inline-block hover:text-dracula-green font-bold">
                            Download AMD64
                        </a>
                        <a href="https://drive.google.com/file/d/1TaPh3_wsM5a6ItV0KzSjUd0g7lRJsm7y/view?usp=drive_link" className="text-dracula-cyan text-lg inline-block hover:text-dracula-green font-bold">
                            Download ARM64
                        </a>
                    </div>
                </section>

                <section className="mb-12">
                    <h2 className="text-dracula-yellow text-4xl text-center mb-2">
                        Linux
                    </h2>
                    <div className="mb-12 flex justify-center space-x-6">
                        <a href="https://drive.google.com/file/d/1PC1f_Ntt0wM7bqLH6kOLWUcOJejD70dw/view?usp=drive_link" className="text-dracula-cyan text-lg inline-block hover:text-dracula-green font-bold">
                            Download AMD64
                        </a>
                        <a href="https://drive.google.com/file/d/1esEocsJguN-PZVkcTS6QGwoug4IhDlTk/view?usp=drive_link" className="text-dracula-cyan text-lg inline-block hover:text-dracula-green font-bold">
                            Download ARM64
                        </a>
                    </div>
                </section>
            </main>
        </>
    );
};

export default BBBVoter;
